import React, { useState, useEffect } from 'react';
import { changeLocale, useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './referralsheader.module.scss';

const ReferralsHeader = () => {
	const intl = useIntl();
	const [langOpen, setLangOpen] = useState(false);

	return (
		<header className={styles.referralsheader}>
			<a href="https://scope.gg" target="_blank" className={styles.referralsheader__logo}>
				<img src="/education/Header-Logo.svg" alt="Scope.gg" />
			</a>
			<div className={styles.referralsheader__lang}>
				<button
					className={styles.referralsheader__lang__btn}
					onClick={() => setLangOpen(!langOpen)}
				>
					{intl.locale}
					<img
						src="/referral/Arrow-Down.svg"
						style={{ 'transform': langOpen ? 'rotate(180deg)' : 'none' }}
					/>
				</button>
				{langOpen ? (
					<div className={styles.referralsheader__lang__menu}>
						<button
							className={styles.referralsheader__lang__item}
							onClick={() => changeLocale('ru')}
						>
							Русский
						</button>
						<button
							className={styles.referralsheader__lang__item}
							onClick={() => changeLocale('en')}
						>
							English
						</button>
					</div>
				) : null}
			</div>
		</header>
	);
};

export default ReferralsHeader;
