import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './howtoget.module.scss';

const HowToGet = () => {
	const intl = useIntl();

	return (
		<section className={styles.howtoget}>
			<div className={styles.howtoget__title}>
				{intl.formatMessage({ id: 'invite.howtoget.title' })}
			</div>
			<div className={styles.howtoget__row}>
				<div className={styles.howtoget__column}>
					<div className={styles.howtoget__column__step}>
						{intl.formatMessage({ id: 'invite.howtoget.steps.one.step' })}
					</div>
					<div className={styles.howtoget__column__title}>
						{intl.formatMessage({ id: 'invite.howtoget.steps.one.title' })}
					</div>
					<div className={styles.howtoget__column__desc}>
						{intl.formatMessage({ id: 'invite.howtoget.steps.one.desc' })}
					</div>
				</div>
				<div className={styles.howtoget__column}>
					<div className={styles.howtoget__column__step}>
						{intl.formatMessage({ id: 'invite.howtoget.steps.two.step' })}
					</div>
					<div className={styles.howtoget__column__title}>
						{intl.formatMessage({ id: 'invite.howtoget.steps.two.title' })}
					</div>
					<div className={styles.howtoget__column__desc}>
						{intl.formatMessage({ id: 'invite.howtoget.steps.two.desc' })}
					</div>
				</div>
				<div className={styles.howtoget__column}>
					<div className={styles.howtoget__column__step}>
						{intl.formatMessage({ id: 'invite.howtoget.steps.three.step' })}
					</div>
					<div className={styles.howtoget__column__title}>
						{intl.formatMessage({ id: 'invite.howtoget.steps.three.title' })}
					</div>
					<div className={styles.howtoget__column__desc}>
						{intl.formatMessage({ id: 'invite.howtoget.steps.three.desc' })}
					</div>
				</div>
			</div>
		</section>
	);
};

export default HowToGet;
