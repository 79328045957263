import React, { useState, useLayoutEffect, useMemo } from 'react';
import * as styles from './carousel.module.scss';

const Carousel = ({ elements = [], itemsPerPage = 1, gap = 0, containerGap = 17 }) => {
	const [currentPage, setCurrentPage] = useState(0);
	const [wrapperWidth, setWrapperWidth] = useState('auto');

	const totalItems = elements.length;
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	const handlePrevClick = () => {
		setCurrentPage((prevPage) => (prevPage === 0 ? totalPages - 1 : prevPage - 1));
	};

	const handleNextClick = () => {
		setCurrentPage((prevPage) => (prevPage === totalPages - 1 ? 0 : prevPage + 1));
	};

	const visibleItems = elements.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

	useLayoutEffect(() => {
		const container = document.getElementById('carouselContainer');
		if (container) {
			const itemWidths = Array.from(container.children)
				.slice(0, itemsPerPage)
				.map((item) => {
					const rect = item.getBoundingClientRect();
					return rect.width;
				});

			const itemsWidth = itemWidths.reduce((sum, width) => sum + width, 0);
			const totalWidth = itemsWidth + gap * (itemsPerPage - 1);

			setWrapperWidth(`${totalWidth}px`);
		}
	}, [visibleItems, itemsPerPage, gap]);

	const scrollExtraGap = useMemo(() => {
		if (currentPage === 0) return '0px';

		return `${currentPage * gap}px`;
	}, [currentPage, gap]);

	return (
		<div className={styles.carousel}>
			<div className={styles.carousel__wrapper} style={{ width: wrapperWidth }}>
				<div
					id="carouselContainer"
					className={styles.carousel__container}
					style={{
						gap: `${containerGap}px`,
						transform: `translateX(calc(-${currentPage * 100}% - ${scrollExtraGap}))`,
						gridGap: `${gap ? `${gap}px` : ''}`,
					}}
				>
					{elements.map((item, index) => (
						<div key={index} className={styles.carousel__item}>{item}</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Carousel;
