import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './clips.module.scss';

const Clips = ({ isLoggedIn, onClickButton }) => {
	const intl = useIntl();

	return (
		<section className={styles.clips}>
			<div className={styles.clips__content}>
				<div className={styles.clips__title}>
					{intl.formatMessage({ id: 'invite.clips.title' })}
				</div>
				<div
					className={styles.clips__desc}
					dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'invite.clips.desc' }) }}
				/>
				<div className={styles.clips__button}>
					{!isLoggedIn ? (
						<button className={styles.referrals_button} onClick={onClickButton}>
							{intl.formatMessage({ id: 'invite.clips.button' })}
						</button>
					) : null}
				</div>
				<div
					className={styles.clips__action}
					dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'invite.clips.action' }) }}
				/>
			</div>
			<div className={styles.clips__video}>
				<video autoPlay muted loop playsInline preload="auto">
					<source src="/video/first-screen/Clip-HD.mp4" type="video/mp4" />
					<source src="/video/first-screen/Clip-HD.webm" type="video/webm" />
				</video>
			</div>
		</section>
	);
};

export default Clips;
