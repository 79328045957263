import { axiosInstance } from '../utils/api';
import cookie from 'js-cookie';

class AuthService {
	getUserData() {
		return axiosInstance.get('/accounts/whoAmI');
	}

	login() {
		cookie.set('redirectUrl', '/prematch', { path: '/', domain: process.env.GATSBY_APP_DOMAIN });

		const origin = window.location.origin;

		window.location = `${process.env.GATSBY_API_URL}/faceIt/auth?successRedirectUrl=${origin}/auth-process?source=faceit&failureRedirectUrl=${origin}/auth-process?source=faceit`;
	}

	getCurrency() {
		return axiosInstance.get('/payment/getCurrency');
	}

	getPrematchPromocodeDetails() {
		return axiosInstance.post('/payment/getPromoCodeDetails', {
			promoCode: 'PREMATCH',
			subscription: 'prematch',
			interval: 'month',
		});
	}
}

export const authService = new AuthService();
