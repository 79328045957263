import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './firstscreen.module.scss';

const FirstScreen = ({ username, isLoggedIn, onClickButton }) => {
	const intl = useIntl();

	return (
		<section className={styles.firstscreen}>
			<div className={styles.firstscreen_title}>
				{intl.formatMessage({ id: 'invite.firstscreen.title'}, { username: username || '...' } )}
			</div>
			<div className={styles.firstscreen_desc}>
				{intl.formatMessage({ id: 'invite.firstscreen.desc' })}
			</div>
			{!isLoggedIn ? (
				<button className={styles.referrals_button} onClick={onClickButton} >
					{intl.formatMessage({ id: 'invite.firstscreen.button' })}
				</button>
			) : null}
		</section>
	);
};

export default FirstScreen;
